exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-app-actions-jsx": () => import("./../../../src/pages/app/actions.jsx" /* webpackChunkName: "component---src-pages-app-actions-jsx" */),
  "component---src-pages-app-analysis-efrag-results-jsx": () => import("./../../../src/pages/app/analysisEfragResults.jsx" /* webpackChunkName: "component---src-pages-app-analysis-efrag-results-jsx" */),
  "component---src-pages-app-analysis-gri-results-jsx": () => import("./../../../src/pages/app/analysisGriResults.jsx" /* webpackChunkName: "component---src-pages-app-analysis-gri-results-jsx" */),
  "component---src-pages-app-analysis-jsx": () => import("./../../../src/pages/app/analysis.jsx" /* webpackChunkName: "component---src-pages-app-analysis-jsx" */),
  "component---src-pages-app-comparator-jsx": () => import("./../../../src/pages/app/comparator.jsx" /* webpackChunkName: "component---src-pages-app-comparator-jsx" */),
  "component---src-pages-app-comparator-results-jsx": () => import("./../../../src/pages/app/comparatorResults.jsx" /* webpackChunkName: "component---src-pages-app-comparator-results-jsx" */),
  "component---src-pages-app-demo-naturgy-jsx": () => import("./../../../src/pages/app/demoNaturgy.jsx" /* webpackChunkName: "component---src-pages-app-demo-naturgy-jsx" */),
  "component---src-pages-app-index-jsx": () => import("./../../../src/pages/app/index.jsx" /* webpackChunkName: "component---src-pages-app-index-jsx" */),
  "component---src-pages-app-my-account-jsx": () => import("./../../../src/pages/app/myAccount.jsx" /* webpackChunkName: "component---src-pages-app-my-account-jsx" */),
  "component---src-pages-app-my-company-jsx": () => import("./../../../src/pages/app/myCompany.jsx" /* webpackChunkName: "component---src-pages-app-my-company-jsx" */),
  "component---src-pages-app-search-jsx": () => import("./../../../src/pages/app/search.jsx" /* webpackChunkName: "component---src-pages-app-search-jsx" */),
  "component---src-pages-cookies-policy-jsx": () => import("./../../../src/pages/cookiesPolicy.jsx" /* webpackChunkName: "component---src-pages-cookies-policy-jsx" */),
  "component---src-pages-data-processing-agreement-jsx": () => import("./../../../src/pages/dataProcessingAgreement.jsx" /* webpackChunkName: "component---src-pages-data-processing-agreement-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-login-forgot-password-jsx": () => import("./../../../src/pages/login/forgot-password.jsx" /* webpackChunkName: "component---src-pages-login-forgot-password-jsx" */),
  "component---src-pages-login-index-jsx": () => import("./../../../src/pages/login/index.jsx" /* webpackChunkName: "component---src-pages-login-index-jsx" */),
  "component---src-pages-login-password-recovery-jsx": () => import("./../../../src/pages/login/password-recovery.jsx" /* webpackChunkName: "component---src-pages-login-password-recovery-jsx" */),
  "component---src-pages-oath-callback-jsx": () => import("./../../../src/pages/oath-callback.jsx" /* webpackChunkName: "component---src-pages-oath-callback-jsx" */),
  "component---src-pages-plans-jsx": () => import("./../../../src/pages/plans.jsx" /* webpackChunkName: "component---src-pages-plans-jsx" */),
  "component---src-pages-privacy-policy-jsx": () => import("./../../../src/pages/privacyPolicy.jsx" /* webpackChunkName: "component---src-pages-privacy-policy-jsx" */),
  "component---src-pages-terms-conditions-jsx": () => import("./../../../src/pages/termsConditions.jsx" /* webpackChunkName: "component---src-pages-terms-conditions-jsx" */),
  "component---src-templates-using-dsg-js": () => import("./../../../src/templates/using-dsg.js" /* webpackChunkName: "component---src-templates-using-dsg-js" */)
}

